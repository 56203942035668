import React, { useState, useCallback, useEffect } from 'react';
import { Sparkles, Wand2, Loader2 } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Question } from '@functions/common/types';
import { toast } from 'react-hot-toast';
import { processRawQuestions } from '../../../utils/questionUtils';

interface AIFeedbackInputProps {
  onSubmitFeedback: (questions: Question[]) => void;
  isEnhancing: boolean;
  currentQuestions: Question[];
}

const AIFeedbackInput: React.FC<AIFeedbackInputProps> = ({
  onSubmitFeedback,
  isEnhancing,
  currentQuestions,
}) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasEnhanced, setHasEnhanced] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [hasText, setHasText] = useState(false);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (feedback.trim() && !isEnhancing && !isSubmitting) {
        setIsSubmitting(true);
        try {
          const functions = getFunctions();
          const enhanceQuestions = httpsCallable<
            {
              currentQuestions: Question[];
              feedback: string;
            },
            { questions: any[] }
          >(functions, 'enhanceInterviewQuestions');

          const result = await enhanceQuestions({
            currentQuestions,
            feedback,
          });

          const processedQuestions = processRawQuestions(result.data.questions);
          onSubmitFeedback(processedQuestions);
          setFeedback('');
          setHasEnhanced(true);
        } catch (error) {
          console.error('Error enhancing questions:', error);
          toast.error('Failed to update questions. Please try again.');
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [feedback, isEnhancing, currentQuestions, onSubmitFeedback]
  );

  useEffect(() => {
    if (!isSubmitting && !isEnhancing && hasEnhanced) {
      toast.success('Questions updated successfully!');
      setHasEnhanced(false);
    }
  }, [isSubmitting, isEnhancing, hasEnhanced]);

  return (
    <form onSubmit={handleSubmit} className="relative">
      <div className="flex items-center bg-white rounded-2xl shadow-lg overflow-hidden border border-blue-200 transition-all duration-300 ease-in-out">
        <Sparkles size={20} className="text-blue-500 ml-3 mr-2" />
        <input
          type="text"
          value={feedback}
          onChange={(e) => {
            setFeedback(e.target.value);
            setHasText(e.target.value.trim().length > 0);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (!feedback.trim()) {
              setIsFocused(false);
            }
          }}
          placeholder="Provide feedback to improve your questions..."
          className="flex-grow px-2 py-3.5 text-1.5sm focus:outline-none bg-transparent"
          disabled={isEnhancing || isSubmitting}
        />
        <button
          type="submit"
          className={`px-5 py-3 bg-transparent rounded-xl flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:scale-105 hover:shadow-lg relative group ${
            isFocused && hasText
              ? 'opacity-100 max-w-[200px] mr-1'
              : 'opacity-0 max-w-0 overflow-hidden pointer-events-none'
          }`}
          disabled={isEnhancing || isSubmitting || !hasText}
        >
          <span className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-600 rounded-xl opacity-50 group-hover:opacity-100 transition-opacity duration-300"></span>
          <span className="absolute inset-[1px] bg-white rounded-[10px]"></span>
          <span className="relative flex items-center justify-center w-full">
            <span className="flex items-center bg-gradient-to-r from-indigo-500 to-purple-600 bg-clip-text text-transparent whitespace-nowrap">
              {isEnhancing || isSubmitting ? (
                <Loader2
                  size={16}
                  className="mr-2 animate-spin text-indigo-500"
                />
              ) : (
                <Wand2 size={16} className="mr-2 text-indigo-500" />
              )}
              <span className="font-medium">
                {isEnhancing || isSubmitting ? 'Updating...' : 'Improve'}
              </span>
            </span>
          </span>
        </button>
      </div>
    </form>
  );
};

export default AIFeedbackInput;
