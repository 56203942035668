import { useState } from 'react';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

export const useOpenAI = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const isDevelopment = process.env.NODE_ENV === 'development';
  const baseUrl = isDevelopment
    ? 'http://127.0.0.1:5001/joinprobe/us-central1'
    : 'https://us-central1-joinprobe.cloudfunctions.net';

  const callOpenAIFunction = async (
    endpoint: string,
    payload: any
  ): Promise<any> => {
    setLoading(true);
    setError(null);

    console.log(`[useOpenAI] Calling ${endpoint} with payload:`, payload);

    try {
      const url = `${baseUrl}/${endpoint}`;
      console.log(`[useOpenAI] Sending request to: ${url}`);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      console.log(`[useOpenAI] Response status:`, response.status);
      console.log(`[useOpenAI] Response headers:`, response.headers);

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`[useOpenAI] Error response body:`, errorText);
        throw new Error(
          `Network response was not ok: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      console.log(`[useOpenAI] Response data:`, data);

      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      setError(`Error calling OpenAI function: ${endpoint}`);
      console.error(`[useOpenAI] Error in ${endpoint}:`, err);
      return null;
    }
  };

  const getChatGPTNextQuestion = async (
    userResponse: string,
    messageHistory: Message[],
    isFirstQuestion: boolean,
    prompt: string
  ): Promise<string | null> => {
    const data = await callOpenAIFunction('getChatGPTNextQuestion', {
      userResponse,
      messageHistory,
      isFirstQuestion,
      prompt,
    });
    return data?.response || null;
  };

  const getChatGPT4NextQuestion = async (
    userResponse: string,
    messageHistory: Message[],
    isFirstQuestion: boolean,
    prompt: string
  ): Promise<string | null> => {
    const data = await callOpenAIFunction('getChatGPT4NextQuestion', {
      userResponse,
      messageHistory,
      isFirstQuestion,
      prompt,
    });
    return data?.response || null;
  };

  const getChatGPTJSONResponse = async (prompt: string): Promise<any> => {
    const data = await callOpenAIFunction('getChatGPTJSONResponse', { prompt });
    return data?.response || null;
  };

  const getChatGPT4JSONResponse = async (
    prompt: string
  ): Promise<string | null> => {
    const data = await callOpenAIFunction('getChatGPT4JSONResponse', {
      prompt,
    });
    return data?.response || null;
  };

  return {
    getChatGPTNextQuestion,
    getChatGPT4NextQuestion,
    getChatGPTJSONResponse,
    getChatGPT4JSONResponse,
    loading,
    error,
  };
};
