import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import InterviewQuestion from './InterviewQuestion/InterviewQuestion.tsx';
import { useClaude } from '../../../hooks/useClaude.ts';
import ProgressBar from '../ProgressBar.tsx';
import { useInterviewAnswers } from '../../../hooks/useInterviewAnswers.ts';
import { useInterviewQuestions } from '../../../hooks/useInterviewQuestions.ts';
import InterviewNavigation from './InterviewNavigation.tsx';
import { updateInterviewInstance } from '../../../utils/firestore.ts';
import { getStudyCompletionCodeFromFirestore } from '../../../utils/firestoreStudy.ts';
import { OpenEndedQuestion } from '@functions/common/types.ts';
import ProbeButton from '../ProbeButton.tsx';
import useGetInterview from '../../../hooks/useGetInterview.ts';
import B2BInterview from './B2Binterview.tsx';

interface InterviewProps {
  interviewId?: string;
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen bg-primary">
    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-primary-contrast"></div>
  </div>
);

const DEFAULT_AI_INSTRUCTIONS =
  "Ask a follow-up question that digs deeper into the respondent's answer, aiming to uncover more detailed insights or underlying motivations.";

const Interview: React.FC<InterviewProps> = ({
  interviewId: propInterviewId,
}) => {
  const { interviewId: paramInterviewId, interviewInstanceId } = useParams<{
    interviewId: string;
    interviewInstanceId?: string;
  }>();

  const interviewId = propInterviewId || paramInterviewId;
  const isRegularInterview = useLocation().pathname.includes('chat');

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentBaseQuestionIndex, setCurrentBaseQuestionIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isOtherFieldActive, setIsOtherFieldActive] = useState(false);
  const [isGeneratingFollowUp, setIsGeneratingFollowUp] = useState(false);
  const [remainingFollowUps, setRemainingFollowUps] = useState(0);
  const [completionCode, setCompletionCode] = useState('');
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const isProlificStudy = useLocation().pathname.includes('study');
  const isB2BProject = useLocation().pathname.includes('b2b_interview');
  const navigate = useNavigate();
  const { askClaude } = useClaude();

  const {
    questions: allQuestions,
    isLoading,
    setQuestions,
  } = useInterviewQuestions(interviewId);

  const { data: interviewData, isLoading: isInterviewDataLoading } =
    useGetInterview(interviewId!, {
      enabled: !!interviewId,
    });

  const questions =
    allQuestions?.filter(
      (q: Question) => q.question && q.question.trim() !== ''
    ) || [];

  const {
    answers,
    handleAnswer,
    instanceId,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    saveAnswers,
    isPreview,
  } = useInterviewAnswers(
    interviewId,
    questions,
    interviewInstanceId,
    !isInterviewDataLoading && !interviewData?.isB2B
  );

  useEffect(() => {
    if (questions && questions.length > 0) {
      const currentQuestion = questions[currentQuestionIndex];
      if (
        (currentQuestion.type === 'open-ended' ||
          currentQuestion.type === 'ai-follow-ups') &&
        currentQuestion.aiFollowUps &&
        currentQuestionIndex === currentBaseQuestionIndex
      ) {
        setRemainingFollowUps(currentQuestion.maxFollowUps || 0);
      }
    }
  }, [questions, currentQuestionIndex, currentBaseQuestionIndex]);

  const hasCurrentAnswer = useCallback(() => {
    if (!questions) return false;
    const currentQuestion = questions[currentQuestionIndex];
    const answer = answers[currentQuestion.id];

    if (currentQuestion.type === 'rating') {
      return typeof answer === 'number';
    }
    if (currentQuestion.type === 'multiple-choice') {
      return Array.isArray(answer) && answer.length > 0;
    }
    if (
      currentQuestion.type === 'single-select' ||
      currentQuestion.type === 'open-ended' ||
      currentQuestion.type === 'ai-follow-ups'
    ) {
      return typeof answer === 'string' && answer.trim() !== '';
    }

    return false;
  }, [questions, currentQuestionIndex, answers]);

  const moveToNextBaseQuestion = useCallback(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentQuestionIndex((prev) => {
        let nextIndex = prev + 1;

        if (nextIndex < questions.length) {
          const nextQuestion = questions[nextIndex];
          setCurrentBaseQuestionIndex(nextIndex);

          if (
            (nextQuestion.type === 'open-ended' ||
              nextQuestion.type === 'ai-follow-ups') &&
            nextQuestion.aiFollowUps
          ) {
            setRemainingFollowUps(nextQuestion.maxFollowUps || 0);
          } else {
            setRemainingFollowUps(0);
          }
        }

        return Math.min(nextIndex, questions.length - 1);
      });
      setIsTransitioning(false);
    }, 500);
  }, [questions, currentQuestionIndex, currentBaseQuestionIndex]);

  const generateFollowUpQuestion = useCallback(
    async (question: OpenEndedQuestion, previousAnswer: string) => {
      setIsGeneratingFollowUp(true);
      const aiInstructions = question.aiInstructions || DEFAULT_AI_INSTRUCTIONS;
      const systemPrompt = `You are an AI assistant helping to conduct an interview. Based on the previous question and answer, generate a follow-up question. Only return the question itself with no other text or context. 1 sentence response is best, max two sentences. DO NOT ask more than one question per response, even if combined into a single sentence. ${aiInstructions}`;
      const messages = [
        {
          role: 'user',
          content: `Previous question: ${question.question}\nPrevious answer: ${previousAnswer}\nGenerate a follow-up question.`,
        },
      ];

      try {
        const followUpQuestion = await askClaude(systemPrompt, messages);

        if (followUpQuestion && questions) {
          const newQuestion: OpenEndedQuestion = {
            id: `${question.id}_followup_${
              (question.maxFollowUps || 0) - remainingFollowUps + 1
            }`,
            type: 'open-ended',
            question: followUpQuestion,
            image: null,
            aiFollowUps: false,
            aiInstructions: question.aiInstructions,
            maxFollowUps: 0,
            allowSkip: question.allowSkip ?? false,
          };
          const updatedQuestions = [...questions];
          updatedQuestions.splice(currentQuestionIndex + 1, 0, newQuestion);
          setQuestions(updatedQuestions);
          return newQuestion;
        }
      } catch (error) {
        console.error('Error generating follow-up question:', error);
      } finally {
        setIsGeneratingFollowUp(false);
      }
      return null;
    },
    [
      askClaude,
      questions,
      currentQuestionIndex,
      remainingFollowUps,
      setQuestions,
    ]
  );

  const handleNext = useCallback(async () => {
    if (!questions || !hasCurrentAnswer()) return;

    const currentQuestion = questions[currentQuestionIndex];
    const currentAnswer = answers[currentQuestion.id];

    if (remainingFollowUps > 0) {
      const followUpQuestion = await generateFollowUpQuestion(
        questions[currentBaseQuestionIndex],
        currentAnswer as string
      );
      if (followUpQuestion) {
        await handleAnswer(followUpQuestion.id, '');
        setCurrentQuestionIndex((prev) => prev + 1);
        setRemainingFollowUps((prev) => prev - 1);
      }
    } else {
      moveToNextBaseQuestion();
    }
  }, [
    questions,
    currentQuestionIndex,
    currentBaseQuestionIndex,
    hasCurrentAnswer,
    answers,
    remainingFollowUps,
    generateFollowUpQuestion,
    handleAnswer,
    moveToNextBaseQuestion,
  ]);

  const handleSubmit = useCallback(
    async (id?: string) => {
      const newInstanceId = id ?? instanceId;
      if (isPreview) {
        navigate(`/completed/${interviewId}`);
        return;
      }

      if (!interviewId || !newInstanceId) {
        console.error('InterviewId or instanceId is missing');
        return;
      }

      try {
        if (hasUnsavedChanges && !isPreview) {
          await updateInterviewInstance(
            interviewId,
            newInstanceId,
            answers,
            questions!
          );
        }
        setHasUnsavedChanges(false);
      } catch (error) {
        console.error('Error submitting final answer:', error);
      }

      if (isProlificStudy) {
        redirectToCompletionScreen();
      } else {
        navigate(`/completed/${interviewId}`);
      }
    },
    [
      interviewId,
      instanceId,
      answers,
      questions,
      hasUnsavedChanges,
      navigate,
      setHasUnsavedChanges,
      isPreview,
      isProlificStudy,
    ]
  );

  const redirectToCompletionScreen = () => {
    window.location.href = `https://app.prolific.com/submissions/complete?cc=${completionCode}`;
  };

  const handleNextOrSubmit = useCallback(async () => {
    if (remainingFollowUps > 0) {
      await handleNext();
    } else if (currentQuestionIndex === questions.length - 1) {
      const newInstanceId = await saveAnswers();
      handleSubmit(newInstanceId);
    } else {
      await saveAnswers();
      moveToNextBaseQuestion();
    }
  }, [
    questions,
    currentQuestionIndex,
    remainingFollowUps,
    handleNext,
    saveAnswers,
    handleSubmit,
    moveToNextBaseQuestion,
  ]);

  const handleBack = useCallback(() => {
    if (currentQuestionIndex > 0 && !isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentQuestionIndex((prev) => {
          let newIndex = prev - 1;
          let baseIndex = currentBaseQuestionIndex;
          while (newIndex > 0 && !questions[newIndex].aiFollowUps) {
            newIndex--;
            if (questions[newIndex].aiFollowUps) {
              baseIndex = newIndex;
            }
          }
          setCurrentBaseQuestionIndex(baseIndex);
          const prevQuestion = questions[baseIndex];
          if (
            (prevQuestion.type === 'open-ended' ||
              prevQuestion.type === 'ai-follow-ups') &&
            prevQuestion.aiFollowUps
          ) {
            const askedFollowUps = questions
              .slice(baseIndex + 1, prev)
              .filter((q: Question) => !q.aiFollowUps).length;
            setRemainingFollowUps(
              (prevQuestion.maxFollowUps || 0) - askedFollowUps
            );
          }
          return newIndex;
        });
        setIsTransitioning(false);
      }, 500);
    }
  }, [
    currentQuestionIndex,
    currentBaseQuestionIndex,
    isTransitioning,
    questions,
  ]);

  const handleSkip = useCallback(() => {
    if (!questions || !questions[currentQuestionIndex].allowSkip) return;

    if (currentQuestionIndex === questions.length - 1) {
      handleSubmit();
    } else {
      moveToNextBaseQuestion();
    }
  }, [questions, currentQuestionIndex, handleSubmit, moveToNextBaseQuestion]);

  useEffect(() => {
    const handleCompletionCode = async () => {
      if (interviewId && interviewInstanceId) {
        const code = await getStudyCompletionCodeFromFirestore(
          interviewId,
          interviewInstanceId
        );
        setCompletionCode(code);
      }
    };

    handleCompletionCode();
  }, [interviewId, interviewInstanceId]);

  const debouncedHandleNextOrSubmit = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(async () => {
      if (remainingFollowUps > 0) {
        await handleNext();
      } else if (currentQuestionIndex === questions.length - 1) {
        const newInstanceId = await saveAnswers();
        handleSubmit(newInstanceId);
      } else {
        await saveAnswers();
        moveToNextBaseQuestion();
      }
    }, 300); // 300ms debounce time
  }, [
    questions,
    currentQuestionIndex,
    remainingFollowUps,
    handleNext,
    saveAnswers,
    handleSubmit,
    moveToNextBaseQuestion,
  ]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        hasCurrentAnswer() &&
        !isImageModalOpen &&
        !isOtherFieldActive &&
        !isGeneratingFollowUp
      ) {
        event.preventDefault();
        debouncedHandleNextOrSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [
    hasCurrentAnswer,
    debouncedHandleNextOrSubmit,
    isImageModalOpen,
    isOtherFieldActive,
    isGeneratingFollowUp,
  ]);

  if (isLoading || isInterviewDataLoading || !questions) {
    return <LoadingSpinner />;
  }

  if (interviewData?.isB2B) {
    return (
      <div>
        <B2BInterview />
      </div>
    );
  }

  const currentQuestion =
    questions && currentQuestionIndex < questions.length
      ? questions[currentQuestionIndex]
      : null;

  if (!currentQuestion) {
    return <div className="text-primary-contrast">Interview completed</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-primary p-8">
      <div className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-xl">
          <InterviewQuestion
            question={currentQuestion}
            onAnswer={handleAnswer}
            selectedAnswer={answers[currentQuestion.id]}
            isTransitioning={isTransitioning}
            questionNumber={currentQuestionIndex + 1}
            totalQuestions={questions.length}
            setIsImageModalOpen={setIsImageModalOpen}
            setIsOtherFieldActive={setIsOtherFieldActive}
            onBlur={saveAnswers}
            followUpQuestion={
              currentQuestion.type === 'ai-follow-ups'
                ? currentQuestion.question
                : undefined
            }
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex-shrink-0 pt-7">
          {isRegularInterview && <ProbeButton />}
        </div>
        <div className="flex-shrink-0">
          <InterviewNavigation
            currentQuestionIndex={currentBaseQuestionIndex}
            totalQuestions={questions.filter((q) => !q.aiFollowUps).length}
            isTransitioning={isTransitioning}
            isGeneratingFollowUp={isGeneratingFollowUp}
            allowSkip={currentQuestion.allowSkip}
            hasCurrentAnswer={hasCurrentAnswer()}
            handleBack={handleBack}
            handleSkip={handleSkip}
            handleNextOrSubmit={debouncedHandleNextOrSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Interview;
