import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import OpenEndedQuestion from './OpenEndedQuestion.tsx';
import MultipleChoiceQuestion from './MultipleChoiceQuestion.tsx';
import SingleChoiceQuestion from './SingleChoiceQuestion.tsx';
import RatingQuestion from './RatingQuestion.tsx';
import { Question } from '@functions/common/types.ts';

interface QuestionFactoryProps {
  question: Question;
  index: number;
  onDelete: () => void;
  onUpdate: (updates: Partial<Question>) => void;
}

const QuestionFactory: React.FC<QuestionFactoryProps> = ({
  question,
  index,
  onDelete,
  onUpdate,
}) => {
  const renderQuestion = () => {
    const props = {
      question,
      onDelete,
      onUpdate,
      questionNumber: index + 1,
    };

    switch (question.type) {
      case 'open-ended':
      case 'ai-follow-ups':
        return <OpenEndedQuestion {...props} />;
      case 'multiple-choice':
        return <MultipleChoiceQuestion {...props} />;
      case 'single-select':
        return <SingleChoiceQuestion {...props} />;
      case 'rating':
        return <RatingQuestion {...props} />;
      default:
        return null;
    }
  };

  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {renderQuestion()}
        </div>
      )}
    </Draggable>
  );
};

export default QuestionFactory;
