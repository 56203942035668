import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Plus, ArrowUpRight, Loader2, RotateCcw } from 'lucide-react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import toast, { Toaster } from 'react-hot-toast';
import QuestionTypeModal from './QuestionTypeModal.tsx';
import QuestionFactory from './Questions/QuestionFactory.tsx';
import AIQuestionGenerator from './AIQuestionGenerator.tsx';
import EstimatedInterviewTime from './EstimatedInterviewTime.tsx';
import {
  fetchInterviewQuestions,
  getInterviewTypeFromFirestore,
  updateInterviewQuestions,
  createCustomInterview,
  createUserDocument,
  getMostRecentInterviewId,
  getStudyTitle,
  saveStudyTitle,
  getStudyDescription,
  saveStudyDescription,
} from '../../../utils/firestore.ts';
import {
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  OpenEndedQuestion,
  RatingQuestion,
  Question,
  QuestionType,
  InterviewType,
} from '@functions/common/types.ts';
import { roundUpToNearest5Minutes } from 'src/utils/general.ts';
import UndoRedoButtons from './UndoRedoButtons';
import ConfirmationModal from '../../ConfirmationModal';
import AIFeedbackInput from './AIFeedbackInput';
import { AnimatePresence, motion } from 'framer-motion';
import ShareModal from './ShareModal';
import InterviewHeader from './InterviewHeader';
import { useAuth } from '../../Authentication/AuthProvider';
import { useOpenAI } from '../../../hooks/useOpenAI';

const MAX_INTERVIEW_TIME = 7 * 60; // 7 minutes in seconds

const InterviewBuilder: React.FC = () => {
  const { interviewId: urlInterviewId } = useParams<{ interviewId: string }>();
  const navigate = useNavigate();
  const [localInterviewId, setLocalInterviewId] = useState<string | undefined>(
    urlInterviewId
  );
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'unsaved'>(
    'saved'
  );
  const [interviewType, setInterviewType] = useState<InterviewType>('CUSTOM');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionsGenerated, setQuestionsGenerated] = useState(false);
  const [isAIGeneratorCollapsed, setIsAIGeneratorCollapsed] = useState(false);
  const [hasGeneratedQuestions, setHasGeneratedQuestions] = useState(false);
  const [history, setHistory] = useState<Question[][]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [pendingGeneratedQuestions, setPendingGeneratedQuestions] = useState<
    Question[]
  >([]);
  const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { user, signInAnonymously } = useAuth();
  const [anonymousId, setAnonymousId] = useState<string | null>(null);
  const isLoggedIn = !!user;
  const isAnonymous = user?.isAnonymous || false;
  const {
    getChatGPT4JSONResponse,
    loading: aiLoading,
    error: aiError,
  } = useOpenAI();

  useEffect(() => {
    const initializeInterview = async () => {
      let userId = user?.uid;

      if (!userId) {
        const anonymousUser = await signInAnonymously();
        if (anonymousUser) {
          userId = anonymousUser.uid;
          setAnonymousId(userId);
          await createUserDocument(userId);
        } else {
          toast.error(
            'An error occurred. Please reload the page and try again.'
          );
          return;
        }
      }

      if (!localInterviewId) {
        try {
          const recentInterviewId = await getMostRecentInterviewId(userId);

          if (recentInterviewId) {
            setLocalInterviewId(recentInterviewId);
            await loadExistingQuestions(recentInterviewId);
          } else {
            const newInterviewId = await createCustomInterview(userId);
            if (newInterviewId) {
              setLocalInterviewId(newInterviewId);
            } else {
              toast.error('Failed to create new interview. Please try again.');
            }
          }
        } catch (error) {
          toast.error('An error occurred. Please try again.');
        }
      } else {
        await loadExistingQuestions(localInterviewId);
      }
    };

    initializeInterview();
  }, [user, signInAnonymously]);

  const loadExistingQuestions = async (interviewId: string) => {
    const existingQuestions = await fetchInterviewQuestions(interviewId);

    if (existingQuestions) {
      setQuestions(existingQuestions);
      if (existingQuestions.length > 0) {
        setIsAIGeneratorCollapsed(true);
      }
    }
    const type = await getInterviewTypeFromFirestore(interviewId);
    setInterviewType(type);
  };

  const estimatedTime = useMemo(() => {
    return questions.reduce((total, question) => {
      if (question.type === 'open-ended' || question.type === 'ai-follow-ups') {
        const openEndedQuestion = question as OpenEndedQuestion;
        const followUpCount =
          openEndedQuestion.aiFollowUps && openEndedQuestion.maxFollowUps
            ? openEndedQuestion.maxFollowUps
            : 0;
        return total + 45 + followUpCount * 45;
      }
      return total + 25;
    }, 0);
  }, [questions]);

  const createQuestion = (type: QuestionType): Question => {
    const currentTime = Date.now();
    const baseQuestion = {
      id: `question-${currentTime}`,
      type,
      question: '',
      image: null,
      allowSkip: false,
    };

    switch (type) {
      case 'open-ended':
      case 'ai-follow-ups':
        return {
          ...baseQuestion,
          type: type as 'open-ended' | 'ai-follow-ups',
          aiFollowUps: type === 'ai-follow-ups',
          aiInstructions: '',
          maxFollowUps: 2,
        };
      case 'multiple-choice':
      case 'rating':
        return {
          ...baseQuestion,
          type: 'rating',
          maxRating: 10,
        } as RatingQuestion;
      case 'single-select':
        return {
          ...baseQuestion,
          type: 'single-select',
          options: [''],
          includeOther: false,
        } as SingleChoiceQuestion;
    }
  };

  const addToHistory = useCallback(
    (questions: Question[]) => {
      setHistory((prev) => [...prev.slice(0, historyIndex + 1), questions]);
      setHistoryIndex((prev) => prev + 1);
    },
    [historyIndex]
  );

  const updateQuestions = useCallback(
    (newQuestions: Question[], addToHistoryFlag: boolean = true) => {
      setQuestions(newQuestions);
      if (addToHistoryFlag) {
        addToHistory(newQuestions);
      }
      setSaveStatus('unsaved');
    },
    [addToHistory]
  );

  const handleUndo = useCallback(() => {
    if (historyIndex > 0) {
      setHistoryIndex((prev) => prev - 1);
      setQuestions(history[historyIndex - 1]);
      setSaveStatus('unsaved');
    }
  }, [history, historyIndex]);

  const handleRedo = useCallback(() => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex((prev) => prev + 1);
      setQuestions(history[historyIndex + 1]);
      setSaveStatus('unsaved');
    }
  }, [history, historyIndex]);

  const addQuestion = (type: QuestionType) => {
    const newQuestion = createQuestion(type);
    updateQuestions([...questions, newQuestion]);
    setIsModalOpen(false);
  };

  const removeQuestion = (id: string) => {
    updateQuestions(questions.filter((q) => q.id !== id));
  };

  const updateQuestion = (id: string, updates: Partial<Question>) => {
    const newQuestions = questions.map((q) =>
      q.id === id ? { ...q, ...updates } : q
    );
    updateQuestions(newQuestions, false);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateQuestions(items);
  };

  const updateDatabase = useCallback(() => {
    if (localInterviewId && saveStatus !== 'saving') {
      setSaveStatus('saving');
      const roundedTime = roundUpToNearest5Minutes(
        Math.ceil(estimatedTime / 60)
      );
      updateInterviewQuestions(localInterviewId, questions, roundedTime)
        .then(() => {
          setSaveStatus('saved');
        })
        .catch((error) => {
          setSaveStatus('unsaved');
        });
    }
  }, [localInterviewId, questions, saveStatus, estimatedTime]);

  useEffect(() => {
    if (saveStatus === 'unsaved') {
      const timer = setTimeout(() => {
        updateDatabase();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [saveStatus, updateDatabase]);

  const validateQuestions = () => {
    for (const question of questions) {
      if (!question.question.trim()) {
        return 'All questions must have a title';
      }
      if (
        (question.type === 'multiple-choice' ||
          question.type === 'single-select') &&
        (
          question as MultipleChoiceQuestion | SingleChoiceQuestion
        ).options.every((option) => !option.trim())
      ) {
        return 'Multiple choice and single select questions must have at least one non-empty option';
      }
    }
    return null;
  };

  const generateStudyTitleAndDescription = async (
    questions: Question[]
  ): Promise<{ title: string | null; description: string | null }> => {
    const titlePrompt = `Generate a concise and engaging title for a research study based on the following interview questions. The title should be no more than 10 words long and capture the essence of the research:

    ${questions.map((q, index) => `${index + 1}. ${q.question}`).join('\n')}

    Please provide only the title, without any additional text or explanation.`;

    const descriptionPrompt = `Generate a one-sentence description for a research study based on the following interview questions. The description should start with "This study will ask ${
      questions.length
    } questions about" and then provide a concise summary of the study's focus:

    ${questions.map((q, index) => `${index + 1}. ${q.question}`).join('\n')}

    Please provide only the one-sentence description, without any additional text or explanation.`;

    try {
      const [titleResult, descriptionResult] = await Promise.all([
        getChatGPT4JSONResponse(titlePrompt),
        getChatGPT4JSONResponse(descriptionPrompt),
      ]);

      let generatedTitle = null;
      let generatedDescription = null;

      if (titleResult) {
        try {
          const parsedTitleResponse = JSON.parse(titleResult);
          generatedTitle = parsedTitleResponse.title.trim();
        } catch (parseError) {
          console.error('Error parsing JSON response for title:', parseError);
        }
      }

      if (descriptionResult) {
        try {
          const parsedDescriptionResponse = JSON.parse(descriptionResult);
          generatedDescription = parsedDescriptionResponse.description.trim();
        } catch (parseError) {
          console.error(
            'Error parsing JSON response for description:',
            parseError
          );
        }
      }

      return { title: generatedTitle, description: generatedDescription };
    } catch (error) {
      console.error('Error generating study title and description:', error);
      return { title: null, description: null };
    }
  };

  const handleStudyTitleAndDescriptionGeneration = async () => {
    if (!localInterviewId) {
      return;
    }

    try {
      const [existingTitle, existingDescription] = await Promise.all([
        getStudyTitle(localInterviewId),
        getStudyDescription(localInterviewId),
      ]);

      if (existingTitle && existingDescription) {
        return;
      }

      const { title, description } =
        await generateStudyTitleAndDescription(questions);

      if (title && !existingTitle) {
        await saveStudyTitle(localInterviewId, title);
      }

      if (description && !existingDescription) {
        await saveStudyDescription(localInterviewId, description);
      }
    } catch (error) {
      console.error(
        'Error handling study title and description generation:',
        error
      );
    }
  };

  const handleSubmit = async () => {
    const validationError = validateQuestions();
    if (validationError) {
      toast.error(validationError);
      return;
    }

    if (
      (interviewType === 'B2C' || interviewType === null) &&
      estimatedTime > MAX_INTERVIEW_TIME
    ) {
      toast.error('Interview cannot exceed 7 minutes');
      return;
    }

    setIsSubmitting(true);

    if (saveStatus !== 'saved') {
      await updateDatabase();
    }

    await handleStudyTitleAndDescriptionGeneration();

    if (localInterviewId) {
      if (interviewType === 'B2C') {
        navigate(`/filters/${localInterviewId}`);
      } else if (interviewType === 'B2B') {
        navigate(`/qualification/${localInterviewId}`);
      } else if (interviewType === 'CUSTOM') {
        navigate(`/dashboard/${localInterviewId}`);
      }
    }

    setIsSubmitting(false);
  };

  const handleGeneratedQuestions = (generatedQuestions: Question[]) => {
    if (questions.length > 0) {
      setIsConfirmModalOpen(true);
      setPendingGeneratedQuestions(generatedQuestions);
    } else {
      applyGeneratedQuestions(generatedQuestions);
    }
  };

  const applyGeneratedQuestions = (generatedQuestions: Question[]) => {
    const newQuestions = generatedQuestions.map((q) => ({
      ...q,
      isAIGenerated: true,
    }));
    updateQuestions(newQuestions);
    setQuestionsGenerated(true);
    setIsAIGeneratorCollapsed(true);
    setHasGeneratedQuestions(true);
  };

  const handleResetQuestions = () => {
    updateQuestions([]);
    setHasGeneratedQuestions(false);
    setQuestionsGenerated(false);
  };

  const handleEnhancedQuestions = (enhancedQuestions: Question[]) => {
    updateQuestions(enhancedQuestions);
    toast.success('Questions updated successfully!');
  };

  const handleCopyLink = () => {
    const interviewLink = `https://heyprobe.com/interview/${localInterviewId}`;
    navigator.clipboard.writeText(interviewLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleCloseModal = () => {
    setIsShareModalOpen(false);
    setIsCopied(false);
  };

  const handleShare = () => {
    if (user && !user.isAnonymous) {
      setIsShareModalOpen(true);
    } else {
      navigate(`/auth/interview/${localInterviewId}`, {
        state: { action: 'share' },
      });
    }
  };

  return (
    <div className="bg-primary relative w-full">
      {questions.length > 0 && (
        <InterviewHeader
          interviewId={localInterviewId || ''}
          saveStatus={saveStatus}
          onShareClick={handleShare}
          isLoggedIn={isLoggedIn}
          isAnonymous={isAnonymous}
        />
      )}

      <div className="min-h-screen w-full">
        <div className="px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-24 max-w-7xl mx-auto pt-6">
          {questions.length === 0 && (
            <div className="mt-4">
              <AIQuestionGenerator
                onQuestionsGenerated={handleGeneratedQuestions}
                isCollapsed={isAIGeneratorCollapsed}
                onToggleCollapse={() =>
                  setIsAIGeneratorCollapsed((prev) => !prev)
                }
              />
            </div>
          )}

          <div className="mb-8">
            <AnimatePresence>
              {questions.length > 0 && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className="my-6 px-2"
                >
                  <AIFeedbackInput
                    onSubmitFeedback={handleEnhancedQuestions}
                    isEnhancing={isEnhancing}
                    currentQuestions={questions}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-6 bg-white py-4 px-3 rounded-lg">
              <EstimatedInterviewTime
                interviewType={interviewType}
                estimatedTime={estimatedTime}
                MAX_INTERVIEW_TIME={MAX_INTERVIEW_TIME}
              />
              <div className="flex flex-wrap items-center space-x-2 md:space-x-4 mt-4 md:mt-0">
                <UndoRedoButtons
                  canUndo={historyIndex > 0}
                  canRedo={historyIndex < history.length - 1}
                  onUndo={handleUndo}
                  onRedo={handleRedo}
                />
                {questions.length > 0 && (
                  <button
                    onClick={handleResetQuestions}
                    className="px-4 py-3 bg-gray-100 text-gray-600 rounded-xl flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:bg-gray-200 hover:scale-105 hover:shadow-lg mt-2 md:mt-0"
                  >
                    <RotateCcw size={16} className="mr-2" /> Reset Questions
                  </button>
                )}
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-4 py-3 bg-primary text-primary-contrast border border-primary-contrast rounded-xl flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:bg-primary-muted hover:text-primary-contrast hover:scale-105 hover:shadow-lg cursor-pointer mt-2 md:mt-0"
                >
                  <Plus size={18} className="mr-2" /> Custom Question
                </button>
              </div>
            </div>

            {/* DragDropContext and questions list */}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="questions">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-4 mb-6"
                  >
                    {questions.map((question, index) => (
                      <QuestionFactory
                        key={question.id}
                        question={question}
                        index={index}
                        onDelete={() => removeQuestion(question.id)}
                        onUpdate={(updates) =>
                          updateQuestion(question.id, updates)
                        }
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {questions.length > 0 && (
              <div className="flex justify-end mt-8">
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="px-6 py-3 bg-primary-contrast text-primary rounded-2xl flex items-center justify-center hover:bg-gray-800 transition-all duration-300 ease-in-out text-md font-medium transform hover:scale-105 hover:shadow-lg"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 size={20} className="mr-2 animate-spin" />{' '}
                      Saving...
                    </>
                  ) : interviewType === 'CUSTOM' ? (
                    <>
                      View responses <ArrowUpRight size={20} className="ml-2" />
                    </>
                  ) : (
                    <>
                      Submit <ArrowUpRight size={20} className="ml-2" />
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
          <QuestionTypeModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSelectType={addQuestion}
          />
          <ConfirmationModal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            onConfirm={() => {
              applyGeneratedQuestions(pendingGeneratedQuestions);
              setIsConfirmModalOpen(false);
            }}
            message="Generating new questions will replace all existing questions. Are you sure you want to continue?"
          />
          <ShareModal
            isOpen={isShareModalOpen}
            onClose={handleCloseModal}
            interviewId={localInterviewId || ''}
          />
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export default InterviewBuilder;
