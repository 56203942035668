import type {
  AnswerWithQuestion,
  InterviewCardData,
  InterviewInstanceWithQuestions,
  InterviewQuestions,
  InterviewResponse,
  InterviewType,
  ProbeScreenerQuestion,
  Question,
} from '@functions/common/types.ts';
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  getCountFromServer,
  serverTimestamp,
  where,
  limit,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { UpdateRespondentInterviewInFirebaseRequest } from 'src/hooks/useUpdateRespondentInterviewOnFirebase.ts';
import { GoalType } from '../components/InterviewGoals/InterviewGoals';
import { db, storage } from '../firebaseConfig.ts';
import { combineAllAnswersWithQuestions } from './individualFunctions/combineAnswersWithQuestions.ts';
import { getFunctions, httpsCallable } from 'firebase/functions';

const createInterviewInstanceOnFirestore = async (
  interviewId: string,
  isPreview = false,
  isStudy = false,
  respondentId?: string,
  anonymousId?: string
) => {
  const interviewInstancesRef = collection(
    db,
    'interviews',
    interviewId,
    'interview_instances'
  );
  const newInterviewInstance = {
    dateAndTimeCreated: serverTimestamp(),
    lastUpdated: serverTimestamp(),
    answers: {},
    isPreview,
    isStudy,
    respondentId,
    anonymousId,
  };

  const docRef = await addDoc(interviewInstancesRef, newInterviewInstance);
  return docRef;
};

const addQuestionAndAnswerToFirestore = async (
  interviewInstanceRef: any,
  question: any,
  answer: any
) => {
  try {
    const timestamp = new Date().toISOString();
    await setDoc(
      interviewInstanceRef,
      {
        data: arrayUnion({
          question,
          answer,
          timestamp,
        }),
      },
      { merge: true }
    );
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const createB2BInterviewOnFirestore = async (
  userId: string,
  anonymousId?: string
) => {
  try {
    const dateAndTimeCreated = new Date().toISOString();

    const interviewRef = await addDoc(collection(db, 'interviews'), {
      dateAndTimeCreated,
      userId: userId || null,
      anonymousId: anonymousId || null,
      interviewType: 'B2B',
    });

    if (userId) {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        publishState: 'DRAFT',
        interviewIds: arrayUnion(interviewRef.id),
        credits: 0,
      });
    }

    return interviewRef;
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const addInterviewGoalsToFirestore = async (
  userId: any,
  interviewRef: any,
  goals: string[]
) => {
  try {
    await setDoc(interviewRef, { goals }, { merge: true });
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const addCollectUsersEmailToFirestore = async (
  interviewRef: any,
  collectUsersEmail: boolean
) => {
  try {
    await setDoc(interviewRef, { collectUsersEmail }, { merge: true });
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getInterviewFromFirestoreWithInterviewId = async (
  interviewId: string
): Promise<InterviewResponse> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnap = await getDoc(interviewRef);

    if (!interviewSnap.exists()) {
      console.log('Interview not found');
      throw new Error('Interview not found');
    }

    const data = interviewSnap.data() as InterviewResponse;
    return { ...data, id: interviewSnap.id };
  } catch (error) {
    console.error('Error fetching interview:', error);
    throw error;
  }
};

const getInterviewGoalsFromFirestore = async (interviewId: string) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      return interviewData.goals;
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getInterviewsFromFirestore = async (userId: string) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      return [];
    }

    const userData = userSnapshot.data();
    const interviewIds = userData.interviewIds || [];

    const interviewPromises = interviewIds.map(async (interviewId: string) => {
      const interviewRef = doc(db, 'interviews', interviewId);
      const interviewSnapshot = await getDoc(interviewRef);

      if (interviewSnapshot.exists()) {
        const interviewData = interviewSnapshot.data();
        const instancesCollectionRef = collection(
          interviewRef,
          'interview_instances'
        );
        const instancesCountSnapshot = await getCountFromServer(
          instancesCollectionRef
        );

        return {
          id: interviewSnapshot.id,
          ...interviewData,
          participants: instancesCountSnapshot.data().count,
        };
      }

      return null;
    });

    const interviewResults = await Promise.all(interviewPromises);
    const validInterviews = interviewResults.filter(
      (interview) => interview !== null
    );

    return validInterviews;
  } catch (error) {
    console.log('Error getting interviews from Firestore:', error);
    return [];
  }
};

const addEmailToFirestoreInterviewInstance = async (
  interviewInstanceRef: any,
  email: any
) => {
  try {
    console.log('Adding email to firestore: ' + email);
    await setDoc(interviewInstanceRef, { userEmail: email }, { merge: true });
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getInterviewRef = (interviewId: string): DocumentReference => {
  const interviewRef = doc(db, 'interviews', interviewId);
  return interviewRef;
};

const editInterviewPreferencesOnFirestore = async (
  interviewId: any,
  goals: GoalType[],
  collectUsersEmail: boolean
) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await updateDoc(interviewRef, { goals, collectUsersEmail });
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getIsCollectUsersEmailFromFirestore = async (interviewId: any) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      return interviewData.collectUsersEmail;
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
    return false;
  }
};

const getPromptFromFirestore = async () => {
  try {
    const promptRef = doc(db, 'prompts', 'prompt-mar-15');
    const promptSnapshot = await getDoc(promptRef);

    if (promptSnapshot.exists()) {
      const prompt = promptSnapshot.data().value;
      return prompt;
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const addPersonalizedPromptToFirestore = async (
  interviewRef: any,
  prompt: any
) => {
  try {
    await setDoc(interviewRef, { prompt }, { merge: true });
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getPersonalizedPromptFromFirestore = async (interviewId: any) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      const customInstructions = interviewData.customInstructions ?? '';
      const combinedPrompt = interviewData.prompt + customInstructions;
      return combinedPrompt;
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const updateInterviewInstanceFinishedStatus = async (
  interviewId: string,
  interviewInstanceId: string,
  isFinished: boolean
) => {
  try {
    const interviewInstanceRef = doc(
      db,
      `interviews/${interviewId}/interview_instances`,
      interviewInstanceId
    );
    await setDoc(interviewInstanceRef, { isFinished }, { merge: true });
    console.log('Interview instance finished status updated on Firestore!');
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getOrderedInterviewInstancesFromFirestore = async (interviewId: any) => {
  try {
    const interviewInstancesRef = collection(
      db,
      `interviews/${interviewId}/interview_instances`
    );
    const querySnapshot = await getDocs(interviewInstancesRef);

    const interviewInstances = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      let normalizedData;

      if (Array.isArray(data.data)) {
        if (data.data[0] && 'question' in data.data[0]) {
          // Old structure
          normalizedData = data.data.flatMap((qa) => [
            {
              content: qa.question,
              type: 'question',
              timestamp: qa.questionTimestamp || data.dateAndTimeCreated,
            },
            {
              content: qa.answer,
              type: 'answer',
              timestamp: qa.answerTimestamp || data.dateAndTimeCreated,
            },
          ]);
        } else {
          // New structure
          normalizedData = data.data;
        }
      } else {
        normalizedData = [];
      }

      return {
        id: doc.id,
        dateAndTimeCreated: data.dateAndTimeCreated,
        data: normalizedData,
        summary: data.summary,
        isFinished: data.isFinished,
      };
    });

    interviewInstances.sort((a, b) => {
      return (
        new Date(b.dateAndTimeCreated).getTime() -
        new Date(a.dateAndTimeCreated).getTime()
      );
    });

    return interviewInstances;
  } catch (e) {
    console.error('Error fetching interview instances: ', e);
    throw e;
  }
};

export type InterviewInstanceResponse = {
  instances: (InterviewInstanceWithQuestions | B2CInterviewInstance)[];
  isB2B: boolean;
  interviewType: InterviewType;
};

const determineInterviewType = (data: any): InterviewType => {
  if (data?.interviewType) {
    return data.interviewType as InterviewType;
  } else if (data?.isB2B !== undefined) {
    return data.isB2B ? 'B2B' : 'B2C';
  } else {
    return 'OLD_TYPE';
  }
};

const getInterviewTypeFromFirestore = async (
  interviewId: string
): Promise<InterviewType> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnap = await getDoc(interviewRef);
    const data = interviewSnap.data();

    return determineInterviewType(data);
  } catch (error) {
    console.error('Error fetching interview type:', error);
    return 'OLD_TYPE';
  }
};

const getInterviewInstancesFromFirestore = async (
  interviewId: string
): Promise<InterviewInstanceResponse> => {
  try {
    const interviewInstancesRef = collection(
      db,
      `interviews/${interviewId}/interview_instances`
    );
    const interviewRef = doc(db, `interviews/${interviewId}`);
    const interviewDoc = await getDoc(interviewRef);
    const interviewData = interviewDoc.data();
    const interviewType = determineInterviewType(interviewData);
    const interviewQuery = query(interviewInstancesRef);
    const querySnapshot = await getDocs(interviewQuery);

    const interviewInstances = querySnapshot.docs.map((doc) => {
      const data = doc.data();

      if (
        interviewType === 'B2B' ||
        interviewType === 'B2C' ||
        interviewType === 'CUSTOM'
      ) {
        let answers: AnswerWithQuestion[] = [];
        if (data.answers && typeof data.answers === 'object') {
          answers = Object.entries(data.answers).map(
            ([questionId, answer]) => ({
              ...(answer as object),
              questionId,
            })
          ) as AnswerWithQuestion[];
        }

        return {
          id: doc.id,
          interviewId: data.interviewId || interviewId,
          dateAndTimeCreated: data.dateAndTimeCreated || null,
          lastUpdated: data.lastUpdated || null,
          answers,
          interviewType: interviewType,
          isB2B: interviewType === 'B2B',
        } as InterviewInstanceWithQuestions;
      } else {
        // Process old interview instance
        let normalizedData;
        if (Array.isArray(data.data)) {
          if (data.data[0] && 'question' in data.data[0]) {
            normalizedData = data.data.flatMap((qa) => [
              {
                content: qa.question,
                type: 'question',
                timestamp: qa.questionTimestamp || data.dateAndTimeCreated,
              },
              {
                content: qa.answer,
                type: 'answer',
                timestamp: qa.answerTimestamp || data.dateAndTimeCreated,
              },
            ]);
          } else {
            normalizedData = data.data;
          }
        } else {
          normalizedData = [];
        }

        return {
          id: doc.id,
          dateAndTimeCreated: data.dateAndTimeCreated || null,
          data: normalizedData,
          summary: data.summary || null,
          isFinished: data.isFinished || false,
          interviewType: interviewType,
          isB2B: false,
        } as B2CInterviewInstance;
      }
    });

    interviewInstances.sort((a, b) => {
      const dateA = a.dateAndTimeCreated
        ? new Date(a.dateAndTimeCreated).getTime()
        : 0;
      const dateB = b.dateAndTimeCreated
        ? new Date(b.dateAndTimeCreated).getTime()
        : 0;
      return dateB - dateA;
    });

    return {
      instances: interviewInstances,
      isB2B: interviewType === 'B2B',
      interviewType,
    };
  } catch (e) {
    console.error('Error fetching interview instances: ', e);
    return { instances: [], isB2B: false, interviewType: 'B2C' };
  }
};

const getInterviewInstanceDataFromFirestore = async (
  interviewId: any,
  interviewinstanceId: any
): Promise<
  InterviewInstanceWithQuestions | B2CInterviewInstance | undefined
> => {
  try {
    const interviewInstanceRef = doc(
      db,
      'interviews',
      interviewId,
      'interview_instances',
      interviewinstanceId
    );
    const interviewInstanceSnapshot = await getDoc(interviewInstanceRef);

    if (interviewInstanceSnapshot.exists()) {
      const interviewInstanceData = interviewInstanceSnapshot.data();
      return interviewInstanceData as any;
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getTopicFromFirestore = async (interviewId: any) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      return {
        topic: interviewData.topic,
      };
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
    return {
      topic: '',
    };
  }
};

const getTopicWithUserIdFromFirestore = async (userId: any) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const interviewRef = doc(db, 'interviews', userData.interviewId);
      const interviewSnapshot = await getDoc(interviewRef);

      if (interviewSnapshot.exists()) {
        const interviewData = interviewSnapshot.data();
        return {
          topic: interviewData.topic,
        };
      } else {
        throw new Error('No such document!');
      }
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.log(error);
    return {
      topic: '',
    };
  }
};

const updateTopicOnFirestore = async (interviewId: string, topic: string) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await setDoc(interviewRef, { topic }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

const updateTopicOnFirestoreGivenInterviewId = async (
  interviewId: any,
  topic: any
) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await setDoc(interviewRef, { topic }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

const updateRephrasedTopicOnFirestoreGivenInterviewId = async (
  interviewId: any,
  rephrasedTopic: any
) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await setDoc(
      interviewRef,
      { rephrasedTopic: rephrasedTopic },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
};

const getRephrasedTopicOnFirestoreGivenInterviewId = async (
  interviewId: any
) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      const rephrasedTopic = interviewData.rephrasedTopic;
      if (rephrasedTopic) {
        return rephrasedTopic;
      } else {
        return interviewData.topic;
      }
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.log(error);
  }
};

const getCreditsFromFirestoreGivenUserId = async (userId: any) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.credits;
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.log(error);
  }
};

const getCreditsFromFirestoreGivenInterviewId = async (interviewId: any) => {
  try {
    const userId = await getUserIdGivenInterviewIdFromFirestore(interviewId);
    const credits = await getCreditsFromFirestoreGivenUserId(userId);
    return credits;
  } catch (error) {
    console.log(error);
  }
};

const getUserIdGivenInterviewIdFromFirestore = async (interviewId: any) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      return interviewData.userId;
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.log(error);
  }
};

const decrementCreditsOnFirestoreGivenInterviewId = async (
  interviewId: any
) => {
  try {
    const userId = await getUserIdGivenInterviewIdFromFirestore(interviewId);
    const userRef = doc(db, 'users', userId);

    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const currentCredits = userDoc.data().credits;

      await updateDoc(userRef, {
        credits: currentCredits - 1,
      });

      console.log('Credits successfully decremented on Firestore!');
    } else {
      console.log('User document does not exist');
    }
  } catch (error) {
    console.log(error);
  }
};

const addSummaryToInterviewInstance = async (
  summary: string,
  interviewId: string,
  interviewInstanceId: string
) => {
  try {
    const interviewInstanceRef = doc(
      db,
      `interviews/${interviewId}/interview_instances`,
      interviewInstanceId
    );
    await setDoc(interviewInstanceRef, { summary }, { merge: true });
    console.log('Summary successfully added to Firestore!');
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const addTestInterviewInstanceDataToFirestore = async (
  interviewId: string,
  questionsAndAnswers: { question: string; answer: string }[]
) => {
  try {
    const interviewInstanceRef =
      await createInterviewInstanceOnFirestore(interviewId);
    const dateAndTimeCreated = new Date().toISOString();

    if (!interviewInstanceRef) {
      throw new Error('Interview instance ref not found');
    }

    await setDoc(
      interviewInstanceRef,
      { dateAndTimeCreated, data: questionsAndAnswers },
      { merge: true }
    );
    console.log(
      'Test interview instance data successfully added to Firestore!'
    );
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const deleteInterviewInstanceOnFirestore = async (
  interviewId: string,
  interviewInstanceId: string
) => {
  try {
    const interviewInstanceRef = doc(
      db,
      `interviews/${interviewId}/interview_instances`,
      interviewInstanceId
    );
    await deleteDoc(interviewInstanceRef);
    console.log('Interview instance successfully deleted from Firestore!');
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getUserIdGivenUserEmailFromFirestore = async (userEmail: any) => {
  try {
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersRef);

    const userDoc = querySnapshot.docs.find(
      (doc) => doc.data().email === userEmail
    );

    if (userDoc) {
      return userDoc.id;
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.log(error);
  }
};

const getInterviewInstanceByRespondentId = async (
  interviewId: string,
  respondentId: string
) => {
  try {
    const interviewInstancesRef = collection(
      db,
      'interviews',
      interviewId,
      'interview_instances'
    );
    const q = query(
      interviewInstancesRef,
      where('respondentId', '==', respondentId),
      limit(1)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    }

    return null;
  } catch (error) {
    console.error('Error getting interview instance by respondent ID:', error);
    throw error;
  }
};

const validateInterviewBelongsToUser = async (
  userId: any,
  interviewId: any
) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.interviewIds.includes(interviewId);
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.log(error);
  }
};

const getAllInterviewInstancesFromFirestore = async (interviewId: string) => {
  const interviewInstancesRef = collection(
    db,
    'interviews',
    interviewId,
    'interview_instances'
  );
  const q = query(interviewInstancesRef, orderBy('dateAndTimeCreated', 'desc'));
  const querySnapshot = await getDocs(q);

  const interviewInstancesData: any[] = [];

  for (const doc of querySnapshot.docs) {
    const interviewInstanceData = doc.data();
    const dataArray = interviewInstanceData.data;
    interviewInstancesData.push(dataArray);
  }

  return interviewInstancesData;
};

export const updateInterviewInFirestore = async (
  interviewId: string,
  updateData: Partial<InterviewData>
) => {
  const interviewRef = doc(db, 'interviews', interviewId);
  await updateDoc(interviewRef, updateData);
};

const addShortenedGoalsToFirestore = async (
  interviewId: string,
  shortenedGoals: any
): Promise<void> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);

    await setDoc(interviewRef, { shortenedGoals }, { merge: true });
    console.log('Shortened goals added to the database.');
  } catch (error) {
    console.error('Error adding shortened goals to Firestore:', error);
    throw error;
  }
};

const getAllInterviewInstanceGoalSummariesFromFirestore = async (
  interviewId: string
) => {
  try {
    const interviewInstancesSnapshot = await getDocs(
      collection(db, `interviews/${interviewId}/interview_instances`)
    );

    const interviewSummaries = await Promise.all(
      interviewInstancesSnapshot.docs.map(async (doc) => {
        const interviewInstanceId = doc.id;
        const interviewInstanceData = doc.data();
        const goalSummaries = interviewInstanceData.goalSummaries || [];
        const dateAndTimeCreated = interviewInstanceData.dateAndTimeCreated;
        return { interviewInstanceId, goalSummaries, dateAndTimeCreated };
      })
    );

    return interviewSummaries;
  } catch (e) {
    console.log('Error: ' + e);
    return [];
  }
};

const getShortenedGoalsFromFirestore = async (interviewId: string) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnapshot = await getDoc(interviewRef);

    if (interviewSnapshot.exists()) {
      const interviewData = interviewSnapshot.data();
      return interviewData.shortenedGoals;
    } else {
      throw new Error('No such document!');
    }
  } catch (e) {
    console.log('Error: ' + e);
    return [];
  }
};

const flagInterviewInstanceAsFinished = async (
  interviewId: string,
  interviewInstanceId: string
) => {
  try {
    const interviewInstanceRef = doc(
      db,
      `interviews/${interviewId}/interview_instances`,
      interviewInstanceId
    );
    await setDoc(interviewInstanceRef, { isFinished: true }, { merge: true });
    console.log('Interview instance successfully flagged as finished.');
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const fetchPayments = async (userId: string) => {
  try {
    const paymentsRef = collection(db, 'users', userId, 'payments');
    const q = query(paymentsRef);
    const querySnapshot = await getDocs(q);
    const paymentsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return paymentsData;
  } catch (error) {
    console.error('Error fetching payments:', error);
    throw error;
  }
};

const getParticipantCreditsFromFirestore = async (userId: string) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.participantCredits;
    } else {
      return 0;
    }
  } catch (error) {
    console.log(error);
  }
};

const addInterviewInstanceMessageToFirestore = async (
  interviewId: string,
  interviewInstanceId: string,
  message: {}
) => {
  try {
    const interviewInstanceRef = doc(
      db,
      `interviews/${interviewId}/interview_instances`,
      interviewInstanceId
    );
    await setDoc(
      interviewInstanceRef,
      {
        data: arrayUnion(message),
      },
      { merge: true }
    );
    console.log('Message successfully added to Firestore!');
  } catch (e) {
    console.log('Error: ' + e);
  }
};

const getInterviewInstancesSummaryFromFirestore = async (
  interviewId: string
): Promise<any[]> => {
  try {
    const interviewInstancesRef = collection(
      db,
      `interviews/${interviewId}/interview_instances`
    );
    const querySnapshot = await getDocs(interviewInstancesRef);

    const interviewInstances = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        dateAndTimeCreated: data.dateAndTimeCreated,
        link: `/dashboard/${interviewId}/${doc.id}`, // Adjust this based on your routing structure
        summaryData: data.summary || '',
        answers: data.answers,
      };
    });

    // Sort the instances in reverse chronological order
    interviewInstances.sort((a, b) => {
      return (
        new Date(b.dateAndTimeCreated).getTime() -
        new Date(a.dateAndTimeCreated).getTime()
      );
    });

    return interviewInstances;
  } catch (e) {
    console.error('Error fetching interview instance summaries: ', e);
    throw e;
  }
};

const getFullInterviewDataForExport = async (
  interviewId: string
): Promise<InterviewInstanceWithQuestions[]> => {
  try {
    const interviewInstancesRef = collection(
      db,
      `interviews/${interviewId}/interview_instances`
    );
    const querySnapshot = await getDocs(interviewInstancesRef);

    const interviewInstances = querySnapshot.docs.map((doc) => {
      const data = doc.data() as InterviewInstanceWithQuestions;
      return {
        ...data,
        id: doc.id,
      };
    });

    // Sort the instances in reverse chronological order
    interviewInstances.sort((a, b) => {
      return (
        new Date(b.dateAndTimeCreated).getTime() -
        new Date(a.dateAndTimeCreated).getTime()
      );
    });

    console.log('Data fetched from Firestore:', interviewInstances);
    return interviewInstances;
  } catch (e) {
    console.error('Error fetching full interview data for export: ', e);
    throw e;
  }
};

const uploadImage = async (
  file: File,
  interviewId: string,
  questionId: string
): Promise<string> => {
  const storageRef = ref(
    storage,
    `interviews/${interviewId}/${questionId}/${file.name}`
  );
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

const updateInterviewQuestions = async (
  interviewId: string,
  questions: Question[],
  estimatedTime: number
) => {
  const interviewRef = doc(db, 'interviews', interviewId);

  return await updateDoc(interviewRef, {
    questions: questions,
    estimatedTime: estimatedTime,
    participantTimeRequiredMinutes: estimatedTime,
    // Add any other fields that need updating
  });
};

const createB2BInterview = async (userId: string): Promise<string | null> => {
  try {
    const interviewRef = await addDoc(collection(db, 'interviews'), {
      userId,
      isB2B: true,
      dateAndTimeCreated: new Date().toISOString(),
    });
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      interviewIds: arrayUnion(interviewRef.id),
    });
    return interviewRef.id;
  } catch (error) {
    console.error('Error creating interview:', error);
    return null;
  }
};

const createB2CInterview = async (userId: string): Promise<string | null> => {
  try {
    const interviewRef = await addDoc(collection(db, 'interviews'), {
      userId,
      isB2B: false,
      interviewType: 'B2C',
      dateAndTimeCreated: new Date().toISOString(),
    });
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      interviewIds: arrayUnion(interviewRef.id),
    });

    return interviewRef.id;
  } catch (error) {
    console.error('Error creating interview:', error);
    return null;
  }
};

const fetchInterviewQuestions = async (
  interviewId: string
): Promise<Question[] | null> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnap = await getDoc(interviewRef);

    if (interviewSnap.exists()) {
      const data = interviewSnap.data();
      return data.questions as Question[];
    } else {
      console.log('No interview found with this ID');
      return null;
    }
  } catch (error) {
    console.error('Error fetching interview questions:', error);
    return null;
  }
};

const createCustomInterview = async (
  userId: string
): Promise<string | null> => {
  try {
    console.log('Creating custom interview for user:', userId);
    const interviewRef = await addDoc(collection(db, 'interviews'), {
      userId: userId,
      interviewType: 'CUSTOM',
      dateAndTimeCreated: new Date().toISOString(),
    });
    console.log('Interview document created with ID:', interviewRef.id);

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      interviewIds: arrayUnion(interviewRef.id),
    });

    return interviewRef.id;
  } catch (error) {
    console.error('Error creating interview:', error);
    return null;
  }
};

const createInterviewInstance = async (
  interviewId: string,
  answers: Record<string, string | string[] | number>,
  questions: InterviewQuestions
): Promise<string | null> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewInstancesCollectionRef = collection(
      interviewRef,
      'interview_instances'
    );

    const combinedAnswers = combineAllAnswersWithQuestions(answers, questions);

    const newInstance: InterviewInstanceWithQuestions = {
      id: '', // This will be set by Firestore
      interviewId,
      dateAndTimeCreated: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
      answers: combinedAnswers,
    };

    const docRef = await addDoc(interviewInstancesCollectionRef, newInstance);

    await updateDoc(docRef, { id: docRef.id });

    return docRef.id;
  } catch (error) {
    console.error('Error creating interview instance:', error);
    return null;
  }
};

const updateInterviewInstance = async (
  interviewId: string,
  instanceId: string,
  answers: Record<string, string | string[] | number>,
  questions: InterviewQuestions,
  finished = false
): Promise<boolean> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const instanceRef = doc(
      collection(interviewRef, 'interview_instances'),
      instanceId
    );
    const combinedAnswers = combineAllAnswersWithQuestions(answers, questions);

    const updateData = {
      answers: combinedAnswers,
      lastUpdated: new Date().toISOString(),
      isFinished: finished,
    };

    await updateDoc(instanceRef, updateData);
    return true;
  } catch (error) {
    console.error('Error updating interview instance:', error);
    return false;
  }
};

const fetchInterviewInstance = async (
  interviewId: string,
  instanceId: string
): Promise<InterviewInstanceWithQuestions | null> => {
  console.log('fetchInterviewInstance called with:', {
    interviewId,
    instanceId,
  });
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const instanceRef = doc(
      collection(interviewRef, 'interview_instances'),
      instanceId
    );

    const instanceSnap = await getDoc(instanceRef);

    if (instanceSnap.exists()) {
      const data = instanceSnap.data() as InterviewInstanceWithQuestions;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching interview instance:', error);
    return null;
  }
};

export interface B2CInterviewInstance {
  id: string;
  dateAndTimeCreated: string;
  data: {
    type: 'question' | 'answer';
    content: string;
    timestamp: string | null;
  }[];
  summary: string | null;
  interviewType: InterviewType;
  respondentId?: string;
  isFinished: boolean;
}

const saveScreenerData = async (
  interviewId: string,
  screenerData: ProbeScreenerQuestion[]
): Promise<boolean> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await updateDoc(interviewRef, { screener: screenerData });
    return true;
  } catch (error) {
    console.error('Error saving screener data:', error);
    return false;
  }
};

export interface Answer {
  type:
    | 'open-ended'
    | 'multiple-choice'
    | 'ai-follow-ups'
    | 'single-select'
    | 'rating';
  originalQuestion: {
    question: string;
    type: string;
    id: string;
    [key: string]: any;
  };
  questionId: string;
  answer?: string;
  selectedOptions?: string[];
  selectedOption?: string;
  selectedRating?: number;
  timestamp: string;
}

interface InterviewInstance {
  id: string;
  dateAndTimeCreated: string;
  link: string;
  summaryData: string;
  answers: Array<Answer>;
}

const getInterviewDashboardCards = async (
  interviewId: string
): Promise<{ cards: InterviewCardData[] }> => {
  const instancesRef = collection(
    db,
    'interviews',
    interviewId,
    'interview_instances'
  );

  const snapshot = await getDocs(query(instancesRef));
  const instances: InterviewInstance[] = snapshot.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id }) as InterviewInstance
  );

  const cards: InterviewCardData[] = instances
    .map((instance) => {
      const firstAnswer =
        instance.answers && instance.answers.length > 0
          ? instance.answers[0]
          : null;

      // Calculate total questions and answered questions
      let totalQuestions = 0;
      let answeredQuestions = 0;

      if (instance.answers) {
        instance.answers.forEach((answer) => {
          totalQuestions++;
          if (
            answer.answer ||
            (answer.selectedOptions && answer.selectedOptions.length > 0) ||
            answer.selectedOption ||
            answer.selectedRating
          ) {
            answeredQuestions++;
          }

          // Count AI follow-ups
          if (answer.aiFollowUps) {
            totalQuestions += answer.aiFollowUps.length;
            answeredQuestions += answer.aiFollowUps.filter(
              (followUp) => followUp.answer
            ).length;
          }
        });
      }

      return {
        id: instance.id,
        dateAndTimeCreated: instance.dateAndTimeCreated,
        link: instance.link,
        firstQuestion: firstAnswer?.originalQuestion.question || '',
        firstAnswer:
          firstAnswer?.answer ||
          firstAnswer?.selectedOptions?.join(', ') ||
          firstAnswer?.selectedOption ||
          firstAnswer?.selectedRating?.toString() ||
          '',
        totalQuestions,
        answeredQuestions,
      };
    })
    .sort((b, a) => {
      return (
        new Date(b.dateAndTimeCreated).getTime() -
        new Date(a.dateAndTimeCreated).getTime()
      );
    });

  return { cards };
};

const updateRespondentInterview = async (
  data: UpdateRespondentInterviewInFirebaseRequest
): Promise<string | null> => {
  try {
    const interviewId = data.interviewId;
    console.log('running update', data);
    const interviewRef = doc(db, 'interviews', interviewId);

    await updateDoc(interviewRef, {
      isB2B: true,
      dateAndTimeCreated: new Date().toISOString(),
      interviewType: 'B2B',
      ...data.data,
    });
    return interviewRef.id;
  } catch (error) {
    console.error('Error creating interview:', error);
    return null;
  }
};

export const deleteInterviewInstance = async (
  interviewId: string,
  interviewInstanceId: string
) => {
  try {
    await deleteInterviewInstanceOnFirestore(interviewId, interviewInstanceId);
    return true;
  } catch (error) {
    console.error('Error deleting interview:', error);
    throw error;
  }
};

const transferAnonymousInterviewToUser = async (
  interviewId: string,
  userId: string
) => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewDoc = await getDoc(interviewRef);

    if (interviewDoc.exists()) {
      const interviewData = interviewDoc.data();
      if (interviewData.anonymousId) {
        await updateDoc(interviewRef, {
          userId: userId,
          anonymousId: null,
        });

        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          interviewIds: arrayUnion(interviewId),
        });

        console.log('Interview transferred successfully');
      } else {
        console.log('Interview is not anonymous, no transfer needed');
      }
    } else {
      console.log('Interview not found');
    }
  } catch (error) {
    console.error('Error transferring interview:', error);
    throw error;
  }
};

export const getMostRecentInterviewId = async (
  userId: string
): Promise<string | null> => {
  try {
    const interviewsRef = collection(db, 'interviews');
    let q = query(
      interviewsRef,
      where('userId', '==', userId),
      orderBy('dateAndTimeCreated', 'desc'),
      limit(1)
    );

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].id;
      }
    } catch (indexError) {
      console.log('Index not ready, falling back to unordered query');
      // If the index is not ready, fall back to an unordered query
      q = query(interviewsRef, where('userId', '==', userId), limit(1));
      const fallbackSnapshot = await getDocs(q);
      if (!fallbackSnapshot.empty) {
        return fallbackSnapshot.docs[0].id;
      }
    }

    return null;
  } catch (error) {
    console.error('Error fetching most recent interview:', error);
    return null;
  }
};

export const updateInterviewUserId = async (
  interviewId: string,
  userId: string
): Promise<void> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await updateDoc(interviewRef, { userId: userId });
    console.log(`Updated interview ${interviewId} with user ID ${userId}`);
  } catch (error) {
    console.error('Error updating interview user ID:', error);
    throw error;
  }
};

export const linkUserAndInterview = async (
  userId: string,
  interviewId: string
): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    const interviewRef = doc(db, 'interviews', interviewId);

    await Promise.all([
      updateDoc(userRef, {
        interviewIds: arrayUnion(interviewId),
      }),
      updateDoc(interviewRef, { userId: userId }),
    ]);
  } catch (error) {
    console.error('Error linking user and interview:', error);
    throw error;
  }
};

const createUserDocument = async (userId: string) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    try {
      await setDoc(userRef, {
        createdAt: serverTimestamp(),
        // Add any other default fields you want for new users
      });
      console.log('User document created for:', userId);
    } catch (error) {
      console.error('Error creating user document:', error);
      throw error;
    }
  }
};

const getStudyTitle = async (interviewId: string): Promise<string | null> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    const interviewSnap = await getDoc(interviewRef);

    if (!interviewSnap.exists()) {
      console.error('Interview not found');
      return null;
    }

    const interviewData = interviewSnap.data();
    return interviewData.studyTitle || null;
  } catch (error) {
    console.error('Error getting study title:', error);
    return null;
  }
};

const saveStudyTitle = async (
  interviewId: string,
  studyTitle: string
): Promise<boolean> => {
  try {
    const interviewRef = doc(db, 'interviews', interviewId);
    await updateDoc(interviewRef, { studyTitle });
    return true;
  } catch (error) {
    console.error('Error saving study title:', error);
    return false;
  }
};

const getStudyDescription = async (
  interviewId: string
): Promise<string | null> => {
  try {
    const interviewDoc = await getDoc(doc(db, 'interviews', interviewId));
    if (interviewDoc.exists()) {
      return interviewDoc.data().studyDescription || null;
    }
    return null;
  } catch (error) {
    console.error('Error getting study description:', error);
    return null;
  }
};

const saveStudyDescription = async (
  interviewId: string,
  description: string
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'interviews', interviewId), {
      studyDescription: description,
    });
  } catch (error) {
    console.error('Error saving study description:', error);
    throw error;
  }
};

export {
  addCollectUsersEmailToFirestore,
  addEmailToFirestoreInterviewInstance,
  addInterviewGoalsToFirestore,
  addInterviewInstanceMessageToFirestore,
  addPersonalizedPromptToFirestore,
  addQuestionAndAnswerToFirestore,
  addShortenedGoalsToFirestore,
  addSummaryToInterviewInstance,
  addTestInterviewInstanceDataToFirestore,
  createB2BInterview,
  createB2CInterview,
  createInterviewInstance,
  createInterviewInstanceOnFirestore,
  createB2BInterviewOnFirestore,
  deleteInterviewInstanceOnFirestore,
  editInterviewPreferencesOnFirestore,
  fetchInterviewInstance,
  fetchInterviewQuestions,
  fetchPayments,
  flagInterviewInstanceAsFinished,
  getAllInterviewInstanceGoalSummariesFromFirestore,
  getAllInterviewInstancesFromFirestore,
  getCreditsFromFirestoreGivenInterviewId,
  getCreditsFromFirestoreGivenUserId,
  getFullInterviewDataForExport,
  getInterviewDashboardCards,
  getInterviewFromFirestoreWithInterviewId,
  getInterviewGoalsFromFirestore,
  getInterviewInstanceDataFromFirestore,
  getInterviewInstancesFromFirestore,
  getInterviewInstancesSummaryFromFirestore,
  getInterviewRef,
  getInterviewsFromFirestore,
  getInterviewTypeFromFirestore,
  getIsCollectUsersEmailFromFirestore,
  getOrderedInterviewInstancesFromFirestore,
  getParticipantCreditsFromFirestore,
  getPersonalizedPromptFromFirestore,
  getPromptFromFirestore,
  getRephrasedTopicOnFirestoreGivenInterviewId,
  getShortenedGoalsFromFirestore,
  getTopicFromFirestore,
  getTopicWithUserIdFromFirestore,
  getUserIdGivenUserEmailFromFirestore,
  saveScreenerData,
  updateInterviewInstance,
  updateInterviewInstanceFinishedStatus,
  updateInterviewQuestions,
  updateRephrasedTopicOnFirestoreGivenInterviewId,
  updateRespondentInterview,
  updateTopicOnFirestore,
  updateTopicOnFirestoreGivenInterviewId,
  uploadImage,
  validateInterviewBelongsToUser,
  createCustomInterview,
  transferAnonymousInterviewToUser,
  createUserDocument,
  getStudyDescription,
  saveStudyDescription,
  getStudyTitle,
  saveStudyTitle,
  getInterviewInstanceByRespondentId,
};
