import { Question, QuestionType } from '@functions/common/types';

export function processRawQuestions(rawQuestions: any[]): Question[] {
  return rawQuestions.map((rawQuestion, index) => {
    const currentTime = Date.now();
    const questionType = rawQuestion.type as QuestionType;

    if (!questionType) {
      console.error(`Question ${index} is missing type:`, rawQuestion);
      // Return a default open-ended question if type is undefined
      return {
        id: `question-${currentTime + (index + 1) * 1000}`,
        type: 'open-ended',
        question: rawQuestion.question || 'Invalid question',
        image: null,
        allowSkip: false,
        aiFollowUps: false,
        aiInstructions: '',
        maxFollowUps: null,
      };
    }

    const baseQuestion = {
      id: rawQuestion.id || `question-${currentTime + (index + 1) * 1000}`,
      type: questionType,
      question: rawQuestion.question,
      image: rawQuestion.image || null,
      allowSkip: rawQuestion.allowSkip ?? false,
    };

    switch (questionType) {
      case 'open-ended':
      case 'ai-follow-ups':
        return {
          ...baseQuestion,
          aiFollowUps: questionType === 'ai-follow-ups',
          aiInstructions: rawQuestion.aiInstructions || '',
          maxFollowUps: rawQuestion.maxFollowUps || null,
        };
      case 'multiple-choice':
      case 'single-select':
        return {
          ...baseQuestion,
          options: rawQuestion.options || [],
          includeOther: rawQuestion.includeOther || false,
        };
      case 'rating':
        return {
          ...baseQuestion,
          maxRating: rawQuestion.maxRating || 10,
        };
      default:
        console.error(`Unsupported question type: ${questionType}`);
        // Return a default open-ended question for unsupported types
        return {
          ...baseQuestion,
          type: 'open-ended',
          aiFollowUps: false,
          aiInstructions: '',
          maxFollowUps: null,
        };
    }
  });
}
