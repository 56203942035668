import React, { useState } from 'react';
import { Copy, Check, X, Share2, BarChart2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  interviewId: string;
}

const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose,
  interviewId,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const handleCopyLink = () => {
    const interviewLink = `https://heyprobe.com/chat/${interviewId}`;
    navigator.clipboard.writeText(interviewLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleViewResponses = () => {
    navigate(`/dashboard/${interviewId}`);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl px-8 pt-6 pb-7 max-w-md w-full shadow-2xl relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <X size={24} />
        </button>
        <div className="flex items-center mb-6">
          <div className="bg-gray-100 p-3 rounded-full mr-4">
            <Share2 size={24} className="text-gray-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900">Share Interview</h2>
        </div>
        <p className="text-gray-600 mb-6">
          Copy the link to share your interview with participants.
        </p>
        <div className="flex items-center bg-gray-100 rounded-lg p-2 mb-6">
          <input
            type="text"
            value={`https://heyprobe.com/interview/${interviewId}`}
            readOnly
            className="flex-grow bg-transparent outline-none text-gray-800 text-sm px-2"
          />
        </div>
        <div className="flex space-x-3">
          <button
            onClick={handleViewResponses}
            className="flex-1 px-4 py-3 bg-gray-200 text-gray-600 rounded-lg flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:bg-gray-300"
          >
            <BarChart2 size={18} className="mr-2" />
            View Responses
          </button>
          <button
            onClick={handleCopyLink}
            className="flex-1 px-4 py-3 bg-gray-900 text-white rounded-lg flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:bg-gray-800"
          >
            {isCopied ? (
              <>
                <Check size={18} className="mr-2" />
                Copied!
              </>
            ) : (
              <>
                <Copy size={18} className="mr-2" />
                Copy Link
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
